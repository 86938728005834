import { FC, useEffect, useState } from "react";
import "./Notification.css";
import { useParams } from "react-router-dom";
import { API, BEARER } from "../../constant";
import { UserData, useAuth } from "../../hooks/Auth";
import NotFound from "../../components/NotFound/NotFound";
import { Card } from "antd";
import INotification from "../../models/notification";

interface NotificationProps {}

const Notification: FC<NotificationProps> = () => {
  const userData = useAuth() as UserData;

  const { notificationId } = useParams<string>();
  const [notification, setNotification] = useState<INotification | null>(null);
  const [,setEdit] = useState<string>("");

  useEffect(() => {
    (async () => {
      try {
        let url = `${API}/notifications/${notificationId}`;
        const data = (
          await (
            await fetch(url, {
              headers: { Authorization: `${BEARER} ${userData.authToken}` },
            })
          ).json()
        ).data;

        setNotification( {...data.attributes, id:notificationId} as INotification);

        console.log({ data });
      } catch (err) {
        // Not found or Error
        setNotification(null);
      }
    })();
  }, [notificationId]);

  return (
    <div
      className="Notification"
      onClick={() => {
        setEdit("");
      }}
    >
      {notification && (
        <>
          <Card title={notification.title}>{notification.message || ""}</Card>
          <br />
        </>
      )}
      {!notification && <NotFound></NotFound>}
    </div>
  );
};

export default Notification;
