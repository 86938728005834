import { Routes, Route } from "react-router-dom";
import Main from "./pages/Main/Main";
import HomeLayout from "./layouts/HomeLayout/HomeLayout";
import Login from "./pages/Login/Login";
import ProtectedLayout from "./layouts/ProtectedLayout/ProtectedLayout";
import Profile from "./pages/Profile/Profile";
import Presets from "./pages/Presets/Presets";
import Projects from "./pages/Projects/Projects";
import Processes from "./pages/Processes/Processes";
import Automations from "./pages/Automations/Automations";
import Notifications from "./pages/Notifications/Notifications";
import Notification from "./pages/Notification/Notification";
import UploadPortal from "./pages/UploadPortal/UploadPortal";
import Dashboard from "./pages/Dashboard/Dashboard";
import Project from "./pages/Project/Project";
import Collections from "./pages/Collections/Collections";
import Collection from "./pages/Collection/Collection";
import Automation from "./pages/Automation/Automation";
import Process from "./pages/Process/Process";
import Preset from "./pages/Preset/Preset";
import Artists from "./pages/Artists/Artists";
import Artist from "./pages/Artist/Artist";
import Artworks from "./pages/Artworks/Artworks";
import Artwork from "./pages/Artwork/Artwork";

export default function App() {
  return (
    <Routes>
      <Route element={<HomeLayout />}>
        <Route path="/" element={<Main />} />
        <Route path="/login" element={<Login />} />
      </Route>

      <Route path="/dashboard/" element={<ProtectedLayout />}>
        <Route path="" element={<Dashboard />} />
        <Route path="login" element={<Login />} />
        <Route path="profile" element={<Profile />} />

        <Route path="presets" element={<Presets />} />
        <Route path="presets/:presetId" element={<Preset />} />

        <Route path="projects" element={<Projects />} />
        <Route path="projects/:projectId" element={<Project />} />

        <Route path="notifications" element={<Notifications />} />
        <Route
          path="notifications/:notificationId"
          element={<Notification />}
        />

        <Route path="upload" element={<UploadPortal />} />
        <Route path="upload/:projectId" element={<UploadPortal />} />

        <Route path="collections" element={<Collections />} />
        <Route path="collections/:uuid" element={<Collection />} />

        <Route path="processes" element={<Processes />} />
        <Route path="processes/:processId" element={<Process />} />

        <Route path="automations" element={<Automations />} />
        <Route path="automations/:automationId" element={<Automation />} />

        <Route path="artists" element={<Artists />} />
        <Route path="artists/:artistId" element={<Artist />} />

        <Route path="artworks" element={<Artworks />} />
        <Route path="artworks/:artworkId" element={<Artwork />} />
      </Route>
    </Routes>
  );
}

// const App = () => {
//   return (

//   );
// };
