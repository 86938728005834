import { FC, useEffect, useState } from "react";
import "./Artwork.css";
import { useNavigate, useParams } from "react-router-dom";
import { API, BEARER } from "../../constant";
import { UserData, useAuth } from "../../hooks/Auth";
import NotFound from "../../components/NotFound/NotFound";
import { Button, Card } from "antd";
import IArtwork from "../../models/artwork";

interface ArtworkProps {}

const Artwork: FC<ArtworkProps> = () => {
  const userData = useAuth() as UserData;

  const { artworkId } = useParams<string>();
  const [artwork, setArtwork] = useState<IArtwork | null>(null);
  const [, setEdit] = useState<string>("");


  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      try {
        let url = `${API}/artworks/${artworkId}?populate=*`;
        const data = (
          await (
            await fetch(url, {
              headers: { Authorization: `${BEARER} ${userData.authToken}` },
            })
          ).json()
        ).data;

        let artist = null;
        if (data.attributes.artist) {
          artist = {
            ...data.attributes.artist.data.attributes,
            id: data.attributes.artist.data.id,
          };
        }
        console.log(artist);
        setArtwork({ ...data.attributes, id: artworkId, artist } as IArtwork);

        console.log({ data });
      } catch (err) {
        // Not found or Error
        setArtwork(null);
      }
    })();
  }, [artworkId]);

  return (
    <div
      className="Artwork"
      onClick={() => {
        setEdit("");
      }}
    >
      {artwork && (
        <>
          <Card title={artwork.title}>
            {artwork.artist?.id && (<p>
              <Button
                    onClick={() => navigate(`/dashboard/artists/${artwork.artist.id}`)}
                    type="link"
                  >{artwork.artist?.name}</Button>
            </p>)}
            {artwork?.description || ""}
          </Card>
          <br />
        </>
      )}
      {!artwork && <NotFound></NotFound>}
    </div>
  );
};

export default Artwork;
