import { FC, useEffect, useState } from "react";
import "./Automation.css";
import { useParams } from "react-router-dom";
import { API, BEARER } from "../../constant";
import { UserData, useAuth } from "../../hooks/Auth";
import NotFound from "../../components/NotFound/NotFound";
import { Card, Table } from "antd";
import IAutomation from "../../models/automation";
import IPreset from "../../models/preset";

interface AutomationProps {}

const Automation: FC<AutomationProps> = () => {
  const userData = useAuth() as UserData;

  const { automationId } = useParams<string>();
  const [automation, setAutomation] = useState<IAutomation | null>(null);
  const [,setEdit] = useState<string>("");

  useEffect(() => {
    (async () => {
      try {
        let url = `${API}/automations/${automationId}?populate=*`;
        const data = (
          await (
            await fetch(url, {
              headers: { Authorization: `${BEARER} ${userData.authToken}` },
            })
          ).json()
        ).data;

        setAutomation( {...data.attributes, id:automationId, preset: {...data.attributes.preset.data.attributes, id:data.attributes.preset.data.id}} as IAutomation);

        console.log({ data });
      } catch (err) {
        // Not found or Error
        setAutomation(null);
      }
    })();
  }, [automationId]);

  return (
    <div
      className="Automation"
      onClick={() => {
        setEdit("");
      }}
    >
      {automation && (
        <>
          <Card title={automation.name}>
          {automation?.description || ""}
          <br />
          <Table dataSource={[
              {
                key: automation.id,
                inputBucket: automation.inputBucket,
                inputType: automation.inputType,
                outputBucket: automation.outputBucket,
                outputType: automation.outputType,
                preset: automation.preset.name,
              },
            ]} 
            columns={[
              {
                title: 'Input Bucket',
                dataIndex: 'inputBucket',
                key: 'inputBucket',
              },
              {
                title: 'Input Bucket',
                dataIndex: 'inputType',
                key: 'inputType',
              },
              {
                title: 'Output Bucket',
                dataIndex: 'outputBucket',
                key: 'outputBucket',
              },
              {
                title: 'Output Bucket',
                dataIndex: 'outputType',
                key: 'outputType',
              },
              {
                title: 'Preset Name',
                dataIndex: 'preset',
                key: 'preset',
              }
            ]
          }
          pagination={false}
          />
          </Card>
        </>
      )}
      {!automation && <NotFound></NotFound>}
    </div>
  );
};

export default Automation;

// name: string;
// description: string;
// preset: IPreset;
// inputBucket: string;
// outputBucket: string;
// inputType: string;
// outputType: string;