import React, { FC } from 'react';
import './Notifications.css';

interface NotificationsProps {}

const Notifications: FC<NotificationsProps> = () => (
  <div className="Notifications">
    Notifications Component
  </div>
);

export default Notifications;
