import './Main.css';

const Main = () => (
  <div className="Main">
    
  </div>
);

Main.propTypes = {};

Main.defaultProps = {};

export default Main;
