import  { useState } from "react";
import "./NotificationIcon.css";
import { Tooltip, Button } from "antd";

// https://react-icons.github.io/react-icons/icons?name=fa
import { FaBell, FaBellSlash } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const NotificationIcon = () => {
  const [pendingNotifications,] = useState(2);

  const navigate = useNavigate();

  return (
    <div className="NotificationIcon">
      {pendingNotifications > 0 ? (
        <Tooltip
          title={`You have ${pendingNotifications} pending notifications`}
        >
          <Button
            className="header_space_brand"
            onClick={ () => navigate('/dashboard/notifications')}
            type="link"
          >
            <FaBell />
            <span className="notNumber">{pendingNotifications}</span>
          </Button>
        </Tooltip>
      ) : (
        <Tooltip title="You have no pending notifications">
          <FaBellSlash />
        </Tooltip>
      )}
    </div>
  );
};

NotificationIcon.propTypes = {};

NotificationIcon.defaultProps = {};

export default NotificationIcon;
