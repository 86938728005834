import { useEffect, useState } from "react";
import "./UploadPortal.css";
import { API, BEARER } from "../../constant";
import { AutoComplete, Button, notification } from "antd";
import { UserData, useAuth } from "../../hooks/Auth";
import { UploadProps } from "antd/lib/upload/Upload";
import Dragger from "antd/lib/upload/Dragger";
import { FaFileUpload } from "react-icons/fa";
import { UploadFile } from "antd/lib/upload";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import IProject from "../../models/project";

const UploadPortal = () => {
  const { projectId } = useParams<string>();

  const navigate = useNavigate();

  const userData = useAuth() as UserData;
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  // Project select - Autocomplete
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState([]);
  const [project, setProject] = useState<IProject | null>(null);

  const onClear = () => {
    fileList.forEach((file) => {
      // @ts-ignore
      if (file.cancelTokenSource)
        // @ts-ignore
        file.cancelTokenSource.cancel("Operation canceled");
    });
    setFileList([]);
  };

  useEffect(() => {
    try {
      if (projectId) {
        (async () => {
          // 1 - search projects containing text:
          const response = await (
            await fetch(`${API}/projects/${projectId}`, {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${userData.authToken}`,
              },
            })
          ).json();
          if (response.data) {
            setProject({...response.data.attributes, id: projectId});
          }
        })();
        return;
      }
      (async () => {
        if (inputValue.length > 1) {
          // 1 - search projects containing text:
          const response = await (
            await fetch(
              //http://api.factory.local/api/projects?filters[name][$containsi]=te
              `${API}/projects?pagination[page]=1&pagination[pageSize]=100&sort[0]=name&filters[name][$containsi]=${inputValue}`,
              {
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${userData.authToken}`,
                },
              }
            )
          ).json();
          if (response.data) {
            const newOptions = response.data.map((d: any) => {
              return {
                label: d.attributes.name,
                value: d.id.toString(),
              };
            });

            setOptions(newOptions);
          }
        }
      })();
    } catch (err) {
      console.error(err);
    }
  }, [inputValue, projectId]);

  const onSelect = (data: any, option: any) => {
    setInputValue(option.label);

    // @ts-ignore
    if (option.value)
      setProject({
        name: option.label,
        id: option.value,
      });
    else setProject(null);

    console.log("onSelect", { data, option });
  };

  const onChange = async (data: any, option: any) => {
    setInputValue(data);

    if (option.value) setProject(option);
    else setProject(null);
  };

  const props: UploadProps = {
    fileList: [...fileList],
    name: "file",
    multiple: true,
    listType: "picture",
    showUploadList: true,
    // directory: true,
    disabled: (project?.name || "") === "",
    headers: { Authorization: `${BEARER} ${userData.authToken}` },
    action: `${API}/minio/presignedUpload/${project?.id}`,
    async customRequest(options: any) {
      const { onProgress, onError, onSuccess, action, headers, file } = options;
      file.status = "pending";

      try {
        const response = await fetch(
          `${action}?path=${file.name}`,
          {
            headers,
          }
        );

        const url = await response.text();
        file.status = "uploading";
        file.cancelTokenSource = axios.CancelToken.source();

        await axios.put(url, file, {
          // headers: { "content-type": "multipart/form-data"  },
          headers: { "content-type": file.type },
          cancelToken: file.cancelTokenSource.token,
          onUploadProgress: ({ loaded, total }) => {
            if (loaded && total) {
              const percent = Math.round((loaded * 100) / total);
              if (percent < 100 && onProgress) {
                file.percent = percent;
                onProgress({ percent });
              }
            }
          },
        });

        file.status = "success";

        file.url = await (
          await fetch(`${API}/minio/url/${project?.id}/${file.name}`, {
            headers,
          })
        ).text();

        if (onSuccess) onSuccess(`${file.name} uploaded`);
      } catch (err) {
        file.status = "error";
        if (axios.isCancel(err)) {
          console.info(err.message);
          file.response = `Upload cancelled by user`;
          notification.warning({
            message: `Upload cancelled for ${file.name}`,
            placement: "topRight",
            duration: 2,
          });
        } else {
          file.response = "server error";
          // @ts-ignore
          if (onError) onError({ err });
        }
      }
    },

    beforeUpload(file: UploadFile, FileList: UploadFile[]) {
      // get file upload URL for each file:

      const newFiles: UploadFile[] = FileList.filter(
        (newFile) =>
          fileList
            .map((existingFile) => existingFile.name)
            .indexOf(newFile.name) === -1
      );
      newFiles.map((f) => {
        f.status = "uploading";
        return f;
      });

      setFileList([...newFiles, ...fileList]);
      // return false;
    },
    onRemove: (file) => {
      // @ts-ignore
      if (file.cancelTokenSource) {
        // @ts-ignore
        file.cancelTokenSource.cancel("Operation canceled");
      }
      file.status = "removed";
      const i = fileList.map((f) => f.uid).indexOf(file.uid);
      fileList.splice(i, 1);
      setFileList([...fileList]);
      return true;
    },
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
      }
      if (status === "done") {
        notification.success({
          message: `File "${info.file.name}" successfully uploaded to project "${project?.name}".`,
          placement: "topRight",
          duration: 2,
        });
      } else if (status === "error") {
        notification.error({
          message: `${info.file.name} file upload failed.`,
          placement: "topRight",
          duration: 2,
        });
      }
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
    progress: {
      // strokeColor: {
      //   "0%": "#108ee9",
      //   "100%": "#87d068",
      // },
      strokeWidth: 3,
      format: (percent) => percent && `${parseFloat(percent.toFixed(2))}%`,
    },
  };
  return (
    <>
      {project?.id && (
        <>
        <h2
          onClick={() => {
            navigate(`/dashboard/projects/${project.id}`);
          }}
        >
          Selected project: {project?.name}
        </h2>
        <code>{project?.uuid}</code>
        </>
      )}

      {!projectId && (
        <>
          <p>Select Project:</p>
          <div>
            <AutoComplete
              value={inputValue}
              options={options}
              autoFocus={true}
              style={{ width: 200 }}
              filterOption={(inputValue, option) =>
                option?.label
                  .toUpperCase()
                  .indexOf(inputValue.toUpperCase()) !== -1
              }
              onSelect={onSelect}
              onChange={onChange}
            />
          </div>
        </>
      )}
      <Button onClick={onClear}>Clear</Button>
      <Dragger {...props}>
        <div className="ant-upload-drag-icon">
          <FaFileUpload></FaFileUpload>
        </div>
        {project?.uuid && (
          <>
            {" "}
            <p className="ant-upload-text">
              Click or drag file to this area to upload files for project{" "}
              {project?.name}
            </p>
            <p className="ant-upload-hint">
              Support for a single or bulk upload.
            </p>
          </>
        )}
        {!project?.uuid && (
          <p className="ant-upload-text">
            A project must be selected before adding the files
          </p>
        )}
      </Dragger>
    </>
  );
};

UploadPortal.propTypes = {};

UploadPortal.defaultProps = {};

export default UploadPortal;
