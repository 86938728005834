import { AUTH_TOKEN, API } from "./constant";
import { UserData } from "./hooks/Auth";

export const getToken = () => {
  return localStorage.getItem("UserDate");
};

export const setToken = (token: string) => {
  if (token) {
    localStorage.setItem(AUTH_TOKEN, token);
  }
};

export const setUserData = (userObj: {
  username: string;
  email: string;
  id: number;
}) => {
  localStorage.setItem("user", JSON.stringify(userObj));
};

export const removeToken = () => {
  localStorage.removeItem(AUTH_TOKEN);
  localStorage.removeItem("user");
};

export const isRegistrar = () => {
  let token = getToken();
  if (!token) return false;
  // check if the token contains the "registrar" role
  return false;
};

export const isAdmin = () => {
  let token = getToken();
  if (!token) return false;

  // check if the token contains the "admin" role
  return false;
};

export const dataFetch = async (
  collection: string,
  page: number,
  pageSize: number,
  search: string = '',
  searchFields: string[] = []
) => {
  const userDataString = localStorage.getItem("userData");
  const userData = JSON.parse(userDataString || "") as UserData;

  const filters = searchFields.map(field => {
    return  `&filters[${field}][$containsi]=${search}`
  });

  return await (
    // 
    await fetch(
      `${API}/${collection}?pagination[page]=${page}&pagination[pageSize]=${pageSize}${filters.join()}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userData.authToken}`,
        },
      }
    )
  ).json();
};
