import { useEffect, useRef } from "react";
import { Button, Card, Col, Form, Input, message, Row, Spin } from "antd";
import { API, BEARER } from "../../constant";
import { useState } from "react";
import { useAuth, UserData } from "../../hooks/Auth";

const Profile = () => {
  const [isLoading, setLoading] = useState(false);
  const userData = useAuth() as UserData;

  const [form] = Form.useForm();

  const formRef = useRef<any>(null);

  

  useEffect(()=>{
    const fetchMe = () =>{
      return new Promise<UserData>((resolve, reject) => {
        return fetch(`${API}/users/${userData.id}?populate=*`, {
          headers: { Authorization: `${BEARER} ${userData.authToken}` },
        }).then((response: Response) => {
          response.json().then((data: UserData) => {
            form.setFieldValue('username', data.username);
            form.setFieldValue('email', data.email);
            form.setFieldValue('about', data.about);         
            resolve(data);
          });
        });
      })
    };

    fetchMe();
  }, [form, userData.authToken, userData.id]);

  const handleProfileUpdate = async (data: any) => {
    setLoading(true);
    try {
      const response = await fetch(`${API}/users/${userData.id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          // set the auth token to the user's jwt
          Authorization: `Bearer ${userData.authToken}`,
        },
        body: JSON.stringify(data),
      });
      const responseData = await response.json();

      console.log({ responseData });
      
      message.success("Data saved successfully!");
    } catch (error) {
      console.error(Error);
      message.error("Error While Updating the Profile!");
    } finally {
      setLoading(false);
    }
  };

  if (isLoading) {
    return <Spin size="large" />;
  }

  return (
    <Card className="profile_page_card">
      <Form
        ref={formRef}
        form={form}
        layout="vertical"       
        onFinish={handleProfileUpdate}
        // onChange={}
      >
        <Row gutter={[16, 16]}>
          <Col md={8} lg={8} sm={24} xs={24}>
            <Form.Item
              label="Username"
              name="username"    
              rules={[
                {
                  required: true,
                  message: "Username is required!",
                  type: "string",
                },
              ]}
            >
              <Input placeholder="Username"  autoComplete="new-username"/>
            </Form.Item>
          </Col>
          <Col md={8} lg={8} sm={24} xs={24}>
            <Form.Item
              label="Email"
              name="email"
              rules={[
                {
                  required: true,
                  message: "Email is required!",
                  type: "email",
                },
              ]}
            >
              <Input placeholder="Email" autoComplete="new-email" />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              label="About"
              name="about"
              rules={[
                {
                  required: false,
                  type: "string",
                  max: 120,
                },
              ]}
            >
              <Input.TextArea placeholder="About" rows={6}/>
            </Form.Item>
          </Col>
        </Row>
        <Button
          className="profile_save_btn"
          htmlType="submit"
          type="primary"
          size="large"
        >
          {isLoading ? (
            <>
              <Spin size="small" /> Saving
            </>
          ) : (
            "Save"
          )}
        </Button>
      </Form>
    </Card>
  );
};

export default Profile;
