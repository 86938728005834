import { FC, useEffect, useState } from "react";
import "./Preset.css";
import { useParams } from "react-router-dom";
import { API, BEARER } from "../../constant";
import { UserData, useAuth } from "../../hooks/Auth";
import NotFound from "../../components/NotFound/NotFound";
import { Card } from "antd";
import IPreset from "../../models/preset";

interface PresetProps {}

const Preset: FC<PresetProps> = () => {
  const userData = useAuth() as UserData;

  const { presetId } = useParams<string>();
  const [preset, setPreset] = useState<IPreset | null>(null);
  const [, setEdit] = useState<string>("");

  useEffect(() => {
    (async () => {
      try {
        let url = `${API}/presets/${presetId}`;
        const data = (
          await (
            await fetch(url, {
              headers: { Authorization: `${BEARER} ${userData.authToken}` },
            })
          ).json()
        ).data;

        setPreset({ ...data.attributes, id: presetId } as IPreset);

        console.log({ data });
      } catch (err) {
        // Not found or Error
        setPreset(null);
      }
    })();
  }, [presetId]);

  return (
    <div
      className="Preset"
      onClick={() => {
        setEdit("");
      }}
    >
      {preset && (
        <>
          <Card title={preset.name}>
            <p>{preset?.description || ""}</p>
            <code>{preset?.command}</code>
          </Card>
          <br />
        </>
      )}
      {!preset && <NotFound></NotFound>}
    </div>
  );
};

export default Preset;
