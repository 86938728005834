import { FC, useEffect, useState } from "react";
import "./Artist.css";
import { useParams } from "react-router-dom";
import { API, BEARER } from "../../constant";
import { UserData, useAuth } from "../../hooks/Auth";
import NotFound from "../../components/NotFound/NotFound";
import { Card } from "antd";
import IArtist from "../../models/artist";

interface ArtistProps {}

const Artist: FC<ArtistProps> = () => {
  const userData = useAuth() as UserData;

  const { artistId } = useParams<string>();
  const [artist, setArtist] = useState<IArtist | null>(null);
  const [,setEdit] = useState<string>("");

  useEffect(() => {
    (async () => {
      try {
        let url = `${API}/artists/${artistId}`;
        const data = (
          await (
            await fetch(url, {
              headers: { Authorization: `${BEARER} ${userData.authToken}` },
            })
          ).json()
        ).data;

        setArtist( {...data.attributes, id:artistId} as IArtist);

        console.log({ data });
      } catch (err) {
        // Not found or Error
        setArtist(null);
      }
    })();
  }, [artistId]);

  return (
    <div
      className="Artist"
      onClick={() => {
        setEdit("");
      }}
    >
      {artist && (
        <>
          <Card title={artist.name}>{artist?.about || ""}</Card>
          <br />
        </>
      )}
      {!artist && <NotFound></NotFound>}
    </div>
  );
};

export default Artist;
