import React, { FC } from 'react';
import './Collections.css';

interface CollectionsProps {}

const Collections: FC<CollectionsProps> = () => (
  <div className="Collections">
    Collections Component
  </div>
);

export default Collections;
