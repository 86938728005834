import { useEffect, useState } from "react";
import "./ProjectFiles.css";
import { List } from "antd";
import { API } from "../../constant";
import { UserData, useAuth } from "../../hooks/Auth";
import IMinioObject from "../../models/minioObject";

interface ProjectFilesProps {
  projectId?: number;
}

const ProjectFiles = (props: ProjectFilesProps) => {
  const { projectId } = props;
  const [files, setFiles] = useState<IMinioObject[]>([]);
  const userData = useAuth() as UserData;

  useEffect(() => {
    (async () => {
      try {
        const data = await (
          await fetch(`${API}/minio/files/${projectId}`, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${userData.authToken}`,
            },
          })
        ).json();
        setFiles(data);
      } catch (err) {
        // Not found or Error
        setFiles([]);
      }
    })();
  }, [projectId]);

  return (
    <div className="ProjectFiles">
      <List
        itemLayout="horizontal"
        dataSource={files}
        renderItem={(file, index) => (
          <List.Item>
            <List.Item.Meta
              title={
                <a href="" target="_blanc">
                  {file.name}
                </a>
              }
              description={`File Size: ${file.size}`}
            />
          </List.Item>
        )}
      ></List>
    </div>
  );
};

export default ProjectFiles;
