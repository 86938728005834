import "./Automations.css";
import {
  Button,
  Drawer,
  Card,
  Col,
  Form,
  Input,
  message,
  Row,
  Spin,
  Space,
  AutoComplete,
  Select,
} from "antd";
import { API } from "../../constant";
import { useEffect, useState } from "react";
import CollectionList from "../../components/CollectionList/CollectionList";
import { UserData, useAuth } from "../../hooks/Auth";
import IAutomation from "../../models/automation";
import IPreset from "../../models/preset";
import Presets from "../Presets/Presets";

const Automations = () => {
  const userData = useAuth() as UserData;

  const [name] = useState(null);
  const [description] = useState(null);
  const [inputBucket] = useState("uploads");
  const [outputBucket] = useState("processed");
  const [inputType] = useState("video/mp4");
  const [outputType] = useState("video/mp4");
  const [preset, setPreset] = useState<IPreset | null>(null);
  const [loading] = useState(false);
  
const contentTypeOptions = [
  {
    label: 'Video',
    options: [
      {
        label: 'mov',
        value: 'video/quicktime',
      },
      {
        label: 'mp4',
        value: 'video/mp4',
      },
      {
        label: 'avi',
        value: 'video/avi',
      },
    ],
  },
  {
    label: 'Image',
    options: [
      {
        label: 'png',
        value: 'image/png',
      },
      {
        label: 'gif',
        value: 'image/gif',
      },
      {
        label: 'jpg',
        value: 'image/jpg',
      },      
    ],
  },
]

  // autocomplete
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState([]);

  const onSelect = (data: any, option: any) => {
    setInputValue(option.label);
    if (option.value) {
      setPreset({
        name: option.label,
        id: option.value,
      });
    } else setPreset(null);

    console.log("onSelect", { data, option });
  };

  const onChange = async (data: any, option: any) => {
    setInputValue(data);
    if (option.value) setPreset(option);
    else setPreset(null);
  };

  useEffect(() => {
    (async () => {
      if (inputValue.length > 1) {
        // 1 - search projects containing text:
        const response = await (
          await fetch(
            `${API}/presets?pagination[page]=1&pagination[pageSize]=100&sort[0]=name&filters[name][$containsi]=${inputValue}`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${userData.authToken}`,
              },
            }
          )
        ).json();
        if (response.data) {
          const newOptions = response.data.map((d: any) => {
            return {
              label: d.attributes.name,
              value: d.id.toString(),
            };
          });

          setOptions(newOptions);
        }
      }
    })();
  }, [inputValue]);

  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  const handleInputTypeChange = () => {
    
  } 
  const handleOutputTypeChange = () => {
    
  } 


  const handleAutomationCreate = async (data: any) => {
    data.preset = preset?.id;
    // setLoading(true);
    try {
      await fetch(`${API}/automations`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userData.authToken}`,
        },
        body: JSON.stringify({ data }),
      });
      message.success("Data saved successfully!");
    } catch (error) {
      console.error(Error);
      message.error("Error while saving the Automation!");
    } finally {
      // setLoading(false);
      onClose();
    }
  };

  return (
    <>
      <Button type="primary" onClick={showDrawer}>
        New Automation
      </Button>

      <Drawer
        title="Create a new automation"
        width={720}
        onClose={onClose}
        open={open}
        bodyStyle={{
          paddingBottom: 80,
        }}
        extra={
          <Space>
            <Button onClick={onClose}>Cancel</Button>
          </Space>
        }
      >
        <Form
          layout="vertical"
          initialValues={{
            name,
            description,
            preset,
            inputBucket,
            outputBucket,
            inputType,
            outputType,
          }}
          onFinish={handleAutomationCreate}
        >
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Form.Item
                label="Automation Name"
                name="name"
                rules={[
                  {
                    required: true,
                    message: "Automation name is required!",
                    type: "string",
                  },
                ]}
              >
                <Input placeholder="Automation name" />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                label="Description"
                name="description"
                rules={[
                  {
                    required: false,
                    type: "string",
                    max: 500,
                  },
                ]}
              >
                <Input.TextArea placeholder="Automation description..." rows={4} />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                label="Preset"
                name="preset"
                rules={[
                  {
                    required: false,
                    type: "number",
                  },
                ]}
              >
                <>
                  <Input hidden />

                  <AutoComplete
                    value={inputValue}
                    options={options}
                    autoFocus={false}
                    style={{ width: 200 }}
                    filterOption={(inputValue, option) =>
                      option?.label
                        .toUpperCase()
                        .indexOf(inputValue.toUpperCase()) !== -1
                    }
                    onSelect={onSelect}
                    onChange={onChange}
                  />
                </>
              </Form.Item>
            </Col>


            <Col span={24}>
              <Form.Item
                label="Input Bucket"
                name="inputBucket"
                rules={[
                  {
                    required: true,
                    type: "string",
                    max: 500,
                  },
                ]}
              >
                <Input placeholder="Input Bucket" />
              </Form.Item>
            </Col>


            <Col span={24}>
              <Form.Item
                label="Output Bucket"
                name="outputBucket"
                rules={[
                  {
                    required: true,
                    type: "string",
                    max: 500,
                  },
                ]}
              >
                <Input placeholder="Output Bucket" />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                label="Input Type"
                name="inputType"
                rules={[
                  {
                    required: true,
                    type: "string",
                    max: 500,
                  },
                ]}
              >
                <Select 
                style={{
                  width: 200,
                }}
                onChange={handleInputTypeChange}
                options={contentTypeOptions}
                />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                label="Output Type"
                name="outputType"
                rules={[
                  {
                    required: true,
                    type: "string",
                    max: 500,
                  },
                ]}
              >
                <Select 
                style={{
                  width: 200,
                }}
                onChange={handleOutputTypeChange}
                options={contentTypeOptions}
                />
              </Form.Item>
            </Col>

          </Row>
          <Button
            className="Automation_save_btn"
            htmlType="submit"
            type="primary"
            size="large"
          >
            {loading ? (
              <>
                <Spin size="small" /> Saving
              </>
            ) : (
              "Save"
            )}
          </Button>
        </Form>
      </Drawer>

      <Card className="Automations">
        <CollectionList
          withDelete={true}
          collection="automations"
          titleField="name"
          descriptionField="description"
          search={["name"]}
          refresh={() => {}}
        ></CollectionList>
      </Card>
    </>
  );
};

Automations.propTypes = {};

Automations.defaultProps = {};

export default Automations;
