import "./Processes.css";
import {
  Card,
} from "antd";
import CollectionList from "../../components/CollectionList/CollectionList";

const Processes = () => {
  return (
    <>
      <Card className="Processes">
        <CollectionList
          withDelete={false}
          collection="processes"
          titleField="uuid"
          descriptionField="status"
          search={["uuid"]}
          refresh={()=>{}}
        ></CollectionList>
      </Card>
    </>
  );
};

Processes.propTypes = {};

Processes.defaultProps = {};

export default Processes;
