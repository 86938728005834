import "./Artists.css";
import {
  Button,
  Drawer,
  Card,
  Col,
  Form,
  Input,
  message,
  Row,
  Spin,
  Space,
} from "antd";
import { API } from "../../constant";
import { useState } from "react";
import CollectionList from "../../components/CollectionList/CollectionList";
import { UserData, useAuth } from "../../hooks/Auth";
import IArtist from "../../models/artist";

const Artists = () => {
  const userData = useAuth() as UserData;

  const [name, ] = useState(null);
  const [about, ] = useState(null);
  const [loading, ] = useState(false);

  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  const handleArtistCreate = async (data: any) => {
    // setLoading(true);
    try {
      await fetch(`${API}/artists`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userData.authToken}`,
        },
        body: JSON.stringify({ data }),
      });
      message.success("Data saved successfully!");
    } catch (error) {
      console.error(Error);
      message.error("Error while saving the Artist!");
    } finally {
      // setLoading(false);
      onClose();
    }
  };

  return (
    <>
      <Button type="primary" onClick={showDrawer}>
        New Artist
      </Button>

      <Drawer
        title="Create a new artist"
        width={720}
        onClose={onClose}
        open={open}
        bodyStyle={{
          paddingBottom: 80,
        }}
        extra={
          <Space>
            <Button onClick={onClose}>Cancel</Button>
          </Space>
        }
      >
        <Form
          layout="vertical"
          initialValues={{
            name,
            about,
          }}
          onFinish={handleArtistCreate}
        >
          <Row gutter={[16, 16]}>
          <Col span={24}>
              <Form.Item
                label="Artist Name"
                name="name"
                rules={[
                  {
                    required: true,
                    message: "Artist name is required!",
                    type: "string",
                  },
                ]}
              >
                <Input placeholder="Artist name" />
              </Form.Item>
            </Col>
            
            <Col span={24}>
              <Form.Item
                label="About"
                name="about"
                rules={[
                  {
                    required: false,
                    message: "Artist \"about\" is required!",
                    type: "string",
                  },
                ]}
              >
                <Input.TextArea placeholder="About..." rows={4} />
              </Form.Item>
            </Col>            
          </Row>
          <Button
            className="Artist_save_btn"
            htmlType="submit"
            type="primary"
            size="large"
          >
            {loading ? (
              <>
                <Spin size="small" /> Saving
              </>
            ) : (
              "Save"
            )}
          </Button>
        </Form>
      </Drawer>

      <Card className="Artists">
        <CollectionList
          withDelete={true}
          collection="artists"
          titleField="name"
          descriptionField="about"
          search={["name"]}
          refresh={()=>{}}
        ></CollectionList>
      </Card>
    </>
  );
};

Artists.propTypes = {};

Artists.defaultProps = {};

export default Artists;
