import { FC, useEffect, useState } from "react";
import "./Process.css";
import { useParams } from "react-router-dom";
import { API, BEARER } from "../../constant";
import { UserData, useAuth } from "../../hooks/Auth";
import NotFound from "../../components/NotFound/NotFound";
import { Card } from "antd";
import IProcess from "../../models/process";

interface ProcessProps {}

const Process: FC<ProcessProps> = () => {
  const userData = useAuth() as UserData;

  const { processId } = useParams<string>();
  const [process, setProcess] = useState<IProcess | null>(null);
  const [,setEdit] = useState<string>("");

  useEffect(() => {
    (async () => {
      try {
        let url = `${API}/processes/${processId}`;
        const data = (
          await (
            await fetch(url, {
              headers: { Authorization: `${BEARER} ${userData.authToken}` },
            })
          ).json()
        ).data;

        setProcess( {...data.attributes, id:processId} as IProcess);

        console.log({ data });
      } catch (err) {
        // Not found or Error
        setProcess(null);
      }
    })();
  }, [processId]);

  return (
    <div
      className="Process"
      onClick={() => {
        setEdit("");
      }}
    >
      {process && (
        <>
          <Card title={process.uuid}>{process?.status || ""}</Card>
          <br />          
        </>
      )}
      {!process && <NotFound></NotFound>}
    </div>
  );
};

export default Process;


// status: string;
// preset: IPreset;
// inputPath: string;
// outputPath: string;
// start: string;
// end: string;