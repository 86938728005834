import { dataFetch } from "../../helpers";
import { List, Pagination, Button, message, Input, Popconfirm } from "antd";
import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { API } from "../../constant";
import { UserData, useAuth } from "../../hooks/Auth";
import { FaQuestionCircle } from "react-icons/fa";

interface ICollectionList {
  collection: string;
  titleField: string;
  descriptionField: string;
  search: string[]; // search fields
  withDelete: any;
  refresh: () => void;
}

const CollectionList = (props: ICollectionList) => {
  const userData = useAuth() as UserData;
  const navigate = useNavigate();

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [data, setData] = useState();
  const [search, setSearch] = useState();

  useEffect(() => {
    if (props && page > 0 && pageSize > 0) {
      dataFetch(props.collection, page, pageSize, search, props.search).then(
        setData
      );
    }
  }, [page, pageSize, props, search]);
  // }, [page, pageSize]);

  if (!data) return <div>loading...</div>;

  const list = (data as { data: any }).data.map(
    (i: { attributes: any; id: number }) =>
      Object.assign(i.attributes, {
        id: i.id,
        title: i.attributes[props.titleField],
        description: i.attributes[props.descriptionField],
      })
  );

  const deleteItem = async (id: number, title: string) => {
    if (!props.withDelete) return;
    try {
      await fetch(`${API}/${props.collection}/${id}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userData.authToken}`,
        },
        body: JSON.stringify({ data }),
      });
      message.success(`Item "${title}" deleted successfully!`);
    } catch (error) {
      console.error(Error);
      message.error("Error while saving the Artwork!");
    } finally {
      dataFetch(props.collection, page, pageSize).then(setData);
    }
  };

  const onSearchChange = (event: any) => {
    setSearch(event.target.value);
  };

  const pagination = (data as { meta: any }).meta.pagination;

  const PaginationComponent = (list.length > 10 && (
    <Pagination
      size="small"
      total={pagination.total}
      showSizeChanger
      onShowSizeChange={(p, s) => setPageSize(s)}
      showQuickJumper
      showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
      defaultPageSize={10}
      pageSize={pagination.pageSize}
      defaultCurrent={1}
      current={pagination.page}
      onChange={setPage}
    />
  )) || <></>;

  return (
    <>
      <Input.Search onChange={onSearchChange}></Input.Search>

      <List
        header={PaginationComponent}
        footer={PaginationComponent}
        itemLayout="horizontal"
        dataSource={list}
        renderItem={(
          item: { id: number; title: string; description: string },
          index
        ) => (
          <List.Item
            extra={
              (props.withDelete && (
                <Popconfirm
                  title="Delete"
                  // description="Are you sure to delete this item?"
                  icon={
                    <FaQuestionCircle
                      style={{
                        color: "red",
                      }}
                    />
                  }
                  onConfirm={() => {
                    deleteItem(item.id, item.title);
                  }}
                >
                  <Button danger size="small">
                    Delete
                  </Button>
                </Popconfirm>
              )) || <></>
            }
          >
            <List.Item.Meta
              title={
                <a
                  onClick={() => {
                    navigate(`/dashboard/${props.collection}/${item.id}`);
                  }}
                >
                  {item.title}
                </a>
              }
              description={item.description}
            />
          </List.Item>
        )}
      ></List>
    </>
  );
};

CollectionList.propTypes = {};

CollectionList.defaultProps = {};

export default CollectionList;
