import { Navigate, useNavigate, useOutlet } from "react-router-dom";
import { Button, Col, Layout, Row, Space } from "antd";
import { FaUserCircle } from "react-icons/fa";
import { Content, Header } from "antd/lib/layout/layout";
import { UserData, useAuth } from "../../hooks/Auth";


const HomeLayout = () => {
  const outlet = useOutlet();
  const navigate = useNavigate();

  try {
    const userData = useAuth() as UserData;

    if (userData?.authToken) {
      return <Navigate to="/dashboard/" replace />;
    }
  }
  catch(err){

  }

  return (
    <>
      <Layout>
        <Row gutter={[0, 32]}>
          <Col span={24}>
            <Header>
            {/* <Space className="header_space"> */}
              <Space >
                <Button className="header_space_brand" onClick={ () => navigate('/')} type="link">
                  <FaUserCircle />
                </Button>
              </Space>
              <Space className="auth_buttons">
                <Button
                  className="auth_button_login"
                  onClick={ () => navigate('/login')}
                  type="link"
                >
                  Login
                </Button>
              </Space>
            </Header>
          </Col>
          <Col span={22} offset={1}>
            <Content>{outlet}</Content>
          </Col>
        </Row>
      </Layout>
    </>
  );
};

export default HomeLayout;
