import {
  Alert,
  Button,
  Card,
  Col,
  Form,
  Input,
  Row,
  Spin,
} from "antd";

import  { Fragment, useState } from "react";
import useScreenSize from "../../hooks/useScreenSize";
import { API, BEARER } from "../../constant";
import IUser from "../../models/user"; 

interface UserCredentials {
  email: string;
  password: string;
}

interface Error {
  status: number;
  name: string;
  message: string;
}

interface AuthResponse {
  jwt: string; 
  user: IUser;
  error?: Error;
}

interface UserData {
  id: number;
  username: string;
  email: string;
  role: string;
  authToken: string;
}

const Login = () => {
  // const { login } = useAuth();

  const { isDesktopView } = useScreenSize();

  // const { setUsername } = useAuthContext();

  const [isLoading, setIsLoading] = useState(false);

  const [error, setError] = useState("");

  const onFinish = async (userCredentials: UserCredentials) => {
    setIsLoading(true);
    try {
      const value = {
        identifier: userCredentials.email,
        password: userCredentials.password,
      };

      const response = await fetch(`${API}/auth/local`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(value),
      });

      const data = await response.json() as AuthResponse;

      if (data?.error) {
        throw data?.error;
      } else {
        const me = await fetch(`${API}/users/me?populate=role`, {
          headers: { Authorization: `${BEARER} ${data.jwt}` },
        });
        
        const user = await me.json() as IUser;

        // set the user
        // setUsername(data.user);
        const userData: UserData = {
          id: user.id,
          username: user.username,
          email: user.email,
          role: user.role.type,
          authToken: data.jwt
        };

        localStorage.setItem('userData', JSON.stringify(userData));

        window.location.href = '/dashboard/projects';
      }
    } catch (error) {
      console.error(error);
      const e = error as Error;
      setError(e?.message ?? "Something went wrong!");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Fragment>
      <Row align="middle">
        <Col span={isDesktopView ? 8 : 24} offset={isDesktopView ? 8 : 0}>
          <Card title="Login">
            {error ? (
              <Alert
                className="alert_error"
                message={error}
                type="error"
                closable
                afterClose={() => setError("")}
              />
            ) : null}
            <Form
              name="basic"
              layout="vertical"
              onFinish={onFinish}
              autoComplete="off"
            >
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  {
                    required: true,
                    type: "email",
                  },
                ]}
              >
                <Input placeholder="Email address" autoComplete="username"/>
              </Form.Item>

              <Form.Item
                label="Password"
                name="password"
                rules={[{ required: true }]}
              >
                <Input.Password placeholder="Password" autoComplete="password"/>
              </Form.Item>

              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="login_submit_btn"
                >
                  Login {isLoading && <Spin size="small" />}
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default Login;
