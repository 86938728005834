import * as jose from "jose";

export interface Role {
  id: number;
  name: string;
  description: string;
  type: string;
}

export interface UserData {
  id: number;
  username: string;
  email: string;
  authToken: string;
  about?: string;
  role: Role;
}

export const useAuth: () => UserData | void = () => {
  try {
    const userDataString = localStorage.getItem("userData");
    const userData = JSON.parse(userDataString || "") as UserData;

    const decodedJWT: jose.JWTPayload = jose.decodeJwt(userData.authToken);
    // is expired? 
    if (Date.now() / 1000 > (decodedJWT?.exp || 0)) {
      return;
    }

    return userData;
  } catch (err) {
    return;
  }
};
