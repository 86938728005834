import React, { FC } from 'react';
import './Collection.css';

interface CollectionProps {}

const Collection: FC<CollectionProps> = () => (
  <div className="Collection">
    Collection Component
  </div>
);

export default Collection;
