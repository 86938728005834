import { FC, useEffect, useState } from "react";
import "./Project.css";
import { useParams } from "react-router-dom";
import { API, BEARER } from "../../constant";
import { UserData, useAuth } from "../../hooks/Auth";
import NotFound from "../../components/NotFound/NotFound";
import { Card } from "antd";
import ProjectFiles from "../../components/ProjectFiles/ProjectFiles";
import IProject from "../../models/project";

interface ProjectProps {}

const Project: FC<ProjectProps> = () => {
  const userData = useAuth() as UserData;

  const { projectId } = useParams<string>();
  const [project, setProject] = useState<IProject | null>(null);
  const [,setEdit] = useState<string>("");

  useEffect(() => {
    (async () => {
      try {
        let url = `${API}/projects/${projectId}`;
        const data = (
          await (
            await fetch(url, {
              headers: { Authorization: `${BEARER} ${userData.authToken}` },
            })
          ).json()
        ).data;

        setProject( {...data.attributes, id:projectId} as IProject);

        console.log({ data });
      } catch (err) {
        // Not found or Error
        setProject(null);
      }
    })();
  }, [projectId]);

  return (
    <div
      className="Project"
      onClick={() => {
        setEdit("");
      }}
    >
      {project && (
        <>
          <Card title={project.name}>{project?.description || ""}</Card>
          <br />
          <Card title="Files in this project">
            <ProjectFiles projectId={project.id}></ProjectFiles>
          </Card>
        </>
      )}
      {!project && <NotFound></NotFound>}
    </div>
  );
};

export default Project;
