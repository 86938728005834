import { Navigate, useNavigate, useOutlet } from "react-router-dom";
// import { useAuth } from "../hooks/useAuth";
import { Button, Col, Layout, Row, Space, Tooltip } from "antd";
import { FaUserCircle } from "react-icons/fa";
import NotificationIcon from "../../components/NotificationIcon/NotificationIcon";
import { Content, Header } from "antd/lib/layout/layout";
import { UserData, useAuth } from "../../hooks/Auth";


const ProtectedLayout = () => {
  const outlet = useOutlet();

  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.clear();
    navigate("/");
    return <Navigate to="/" />;
  };

  let username = "";

  try {
    const userData = useAuth() as UserData;

    if (!userData || !userData.authToken) {
      return handleLogout();
    }

    username = userData.username;
  } catch (err) {
    return handleLogout();
  }

  return (
    <>
      <Layout>
        <Row gutter={[0, 32]}>
          <Col span={24}>
            <Header>
              {/* <Space className="header_space"> */}
              <Space>
                <Tooltip title={username}>

                  <Button
                    className="header_space_brand"
                    onClick={() => navigate("/dashboard/profile")}
                    type="link"
                  >
                    <FaUserCircle />
                  </Button>
                  
                </Tooltip>
              </Space>

              <Space className="auth_buttons">

                <Button
                  onClick={() => navigate("/dashboard/projects")}
                  type="link"
                >
                  Projects
                </Button>

                <Button
                  onClick={() => navigate("/dashboard/upload")}
                  type="link"
                >
                  Upload
                </Button>

                <Button
                  onClick={() => navigate("/dashboard/presets")}
                  type="link"
                >
                  Presets
                </Button>

                <Button
                  onClick={() => navigate("/dashboard/automations")}
                  type="link"
                >
                  Automations
                </Button>

                <Button
                  onClick={() => navigate("/dashboard/processes")}
                  type="link"
                >
                  Processes
                </Button>

                <Button
                  onClick={() => navigate("/dashboard/artists")}
                  type="link"
                >
                  Artists
                </Button>

                <Button
                  onClick={() => navigate("/dashboard/artworks")}
                  type="link"
                >
                  Artworks
                </Button>

                <Button
                  onClick={() => navigate("/dashboard/collections")}
                  type="link"
                >
                  Collections
                </Button>


                <NotificationIcon></NotificationIcon>
                <Button
                  className="auth_button_signUp"
                  type="primary"
                  onClick={handleLogout}
                >
                  Logout
                </Button>
              </Space>
            </Header>
          </Col>
          <Col span={22} offset={1}>
            <Content>{outlet}</Content>
          </Col>
        </Row>
      </Layout>
    </>
  );
};

export default ProtectedLayout;
